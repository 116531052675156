import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Point } from '@turf/turf';

@Component({
	selector: 'agdir-location-coordinates-dialog',
	template: `
		<form [formGroup]="form">
			<mat-dialog-content>
				<mat-form-field class="w-full">
					<mat-label transloco="general.maps.longitude"></mat-label>
					<input matInput formControlName="lng" />
				</mat-form-field>
				<mat-form-field class="w-full">
					<mat-label transloco="general.maps.latitude"></mat-label>
					<input matInput formControlName="lat" />
				</mat-form-field>
			</mat-dialog-content>
			<mat-dialog-actions>
				<button type="button" mat-button transloco="general.cancelText" (click)="cancel()"></button>
				<button type="submit" mat-button transloco="general.saveText" (click)="saveAndClose()"></button>
			</mat-dialog-actions>
		</form>
	`,
})
export class LocationCoordinatesDialogComponent {
	form = new UntypedFormGroup({
		lat: new UntypedFormControl(null, [Validators.required]),
		lng: new UntypedFormControl(null, [Validators.required]),
	});

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matRef: MatDialogRef<LocationCoordinatesDialogComponent, Point>) {
		this.form.patchValue(data.preset);
	}

	cancel() {
		this.matRef.close();
	}

	saveAndClose() {
		const { lat, lng } = this.form.value;
		this.matRef.close({ type: 'Point', coordinates: [lng, lat] });
	}
}
