import { Component, EventEmitter } from '@angular/core';

@Component({
	selector: 'agdir-mapbox-split-control',
	templateUrl: './mapbox-split-control.component.html',
	styleUrls: ['./mapbox-split-control.component.scss'],
})
export class MapboxSplitControlComponent {
	onSplitButtonClick: EventEmitter<void> = new EventEmitter<void>();
	drawEnabled = false;

	splitBtnClick() {
		this.onSplitButtonClick.emit();
	}
}
