import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Feature, Polygon } from '@turf/turf';
import { AnyPaint, MapMouseEvent, MapTouchEvent } from 'mapbox-gl';

// this is just proxy component for polygon projection from agdir-map -> ngx-mapbox-gl
@Component({
	selector: 'agdir-map-polygon',
	template: '<ng-content></ng-content>',
})
export class MapPolygonComponent {
	@Input() name?: string;
	@Input() feature?: Feature<Polygon>;
	@Input() deletable = true;
	@Input() fillPaint: AnyPaint | undefined;
	@Input() outlinePaint: AnyPaint | undefined;
	@Input() symbolPaint: AnyPaint | undefined;
	@Output() polygonClick = new EventEmitter<MapMouseEvent | MapTouchEvent>();
}
